import { UseInfiniteQueryResult } from "@tanstack/react-query";
import React, { useEffect, useRef } from "react";
import { BundleList } from "../../types/bundle";
import LoadingError from "../loading-error";
import { Link } from "react-router-dom";
import CardBundle, { CardBundleSkeleton } from "./card-bundle";

type CardContainerBundlesProps = {
  bundles: UseInfiniteQueryResult<BundleList>;
  label?: string;
  path?: string;
  showMore?: boolean;
  bundlesPerPage: number;
};

const CardContainerBundles = (props: CardContainerBundlesProps) => {
  const {
    data,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = props.bundles;

  // sposta il focus al primo elemento caricato nella pagina successiva
  // è fatto calcolandosi lo spiazzamento per evitare di gestire una ref per ogni figlio
  useEffect(() => {
    if (!data || isFetching || isFetchingNextPage || !ulRef.current) {
      return;
    }
    if (data.pages.length <= 1) {
      return;
    }
    const children = Array.from(ulRef.current.childNodes);
    const index = props.bundlesPerPage! * (data.pages.length - 1);

    if (index >= children.length) {
      return;
    }

    (children[index] as HTMLLIElement).focus();
  }, [data, isFetchingNextPage, props.bundlesPerPage, isFetching]);

  const ulRef = useRef<HTMLUListElement>(null);

  const items =
    data?.pages
      .flat()
      .map((p) => p.pearsonBundles.items)
      .flat() ?? [];

  return (
    <div className="card-container-bundles">
      {props.label && <div className="card-container-bundles__label">{props.label}</div>}
      {isError && (
        <div className="card-container-bundles__error">
          <LoadingError container={false} className="error-block--page" />
        </div>
      )}
      {isFetching && !isRefetching && !isFetchingNextPage && (
        <ul className="card-container-bundles__content list">
          {Array.from({ length: 4 }).map(() => (
            <li>
              <CardBundleSkeleton />
            </li>
          ))}
        </ul>
      )}
      {items.length > 0 && (
        <ul className="card-container-bundles__content list" ref={ulRef}>
          {items.map((i) => {
            return (
              // il tab index serve per poter focussare su una card specifica al load more per accessibilità
              <li tabIndex={-1} className="nofocus">
                <CardBundle item={i} />
              </li>
            );
          })}
          {isFetchingNextPage &&
            Array.from({ length: 4 }).map(() => (
              <li>
                <CardBundleSkeleton />
              </li>
            ))}
        </ul>
      )}
      {props.path && items && (
        <Link className="card-container-bundles__see-all button" to={props.path}>
          Visualizza tutti
        </Link>
      )}
      {props.showMore && hasNextPage && !isFetchingNextPage && (
        <button
          className="card-container-bundles__see-more button button--secondary"
          onClick={() => fetchNextPage()}
        >
          Carica di più
        </button>
      )}
    </div>
  );
};

export default CardContainerBundles;
