import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { Bundle } from "../../types/bundle";
import classNames from "classnames";
import Icon from "../icon";
import debounce from "lodash.debounce";

type BundleDetailHeaderProps = {
  bundle: Bundle;
  selected: number;
  setSelected: (i: number) => void;
  initialSlide?: number;
};

const TAB_GAP = 8;

const getArrowWidth = () => (window.innerWidth < 768 ? 50 : 100);
const getItemsPerView = () => {
  if (window.innerWidth < 576) {
    return 1;
  }
  if (window.innerWidth < 768) {
    return 2;
  }
  return 3;
};

const useArrowWidth = () => {
  const [width, setWidth] = useState(getArrowWidth());

  useEffect(() => {
    const l = () => setWidth(getArrowWidth());
    window.addEventListener("resize", l);
    window.screen.orientation.addEventListener("change", l);
    return () => window.removeEventListener("resize", l);
  }, []);

  return width;
};

const useItemsPerView = () => {
  const [items, setItems] = useState(getItemsPerView());

  useEffect(() => {
    const l = () => setItems(getItemsPerView());

    window.addEventListener("resize", l);
    window.screen.orientation.addEventListener("change", l);
    return () => window.removeEventListener("resize", l);
  }, []);
  return items;
};

const BundleDetailHeader = (props: BundleDetailHeaderProps) => {
  const [scrollX, setScrollX] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [arrowsOnScroll, setArrowsOnScroll] = useState(0);

  const arrowWidth = useArrowWidth();
  const itemsPerView = useItemsPerView();

  const getMaxScroll = useCallback(
    (items: number, itemsPerView: number, arrowsOnScroll: number, arrowWidth: number) => {
      // Se items < items per view devo clampare a zero per evitare valori negativi di max scroll
      if (items - itemsPerView <= 0) return 0;
      // dimensione disponibile del contenitore / n. di elementi
      return (
        ((wrapperRef?.current?.clientWidth! - arrowWidth * arrowsOnScroll) / itemsPerView) *
          // numero massimo di elementi che possono essere scrollati.
          (items - itemsPerView) -
        TAB_GAP
      );
    },
    []
  );

  const onClick = useCallback(
    (direction: "left" | "right") => {
      if (!wrapperRef.current) {
        return;
      }
      // non vanno presi dallo stato perché non sono aggiornati dopo che è stato sparato l'evento del click sui pulsanti
      const itemsPerView = getItemsPerView();
      const arrowWidth = getArrowWidth();
      const items = props.bundle.products.length;
      const sign = direction === "right" ? 1 : -1;
      // in base alla slide dove atterro alla fine dello scroll posso avere una o due freccette
      const arrowsOnScroll = (() => {
        if (clickCount + sign === 0) {
          return 1;
        }
        if (clickCount + sign === items - itemsPerView) {
          return 1;
        }
        return 2;
      })();

      setClickCount(Math.min(Math.max(clickCount + sign, 0), items - itemsPerView));
      // non devo mai scrollare oltre il numero di slide totali (-1 se ne faccio vedere 2 alla volta)

      const maxScroll = getMaxScroll(items, itemsPerView, arrowsOnScroll, arrowWidth);
      setScrollX(() => {
        const newValue =
          ((wrapperRef.current?.clientWidth! - arrowWidth * arrowsOnScroll) / itemsPerView) *
          // quanti elementi sono stati scrollati
          // +1 se ho appena scrollato vs destra
          // -1 se verso sinistra
          (clickCount + sign);
        return Math.min(Math.max(newValue, 0), maxScroll);
      });
      setArrowsOnScroll(arrowsOnScroll);
    },
    [clickCount, getMaxScroll, props.bundle.products.length]
  );

  // slida al mount se bisogna atterrarre ad una certa slide
  useEffect(() => {
    if (!props.initialSlide) {
      return;
    }

    if (props.initialSlide >= props.bundle.products.length) {
      return;
    }

    // non vanno presi dallo stato perché non sono aggiornati dopo che è stato sparato l'evento del click sui pulsanti
    const itemsPerView = getItemsPerView();
    const clickCount = itemsPerView === 2 ? props.initialSlide - 1 : props.initialSlide;
    const items = props.bundle.products.length;
    const arrowWidth = getArrowWidth();
    const arrowsOnScroll = (() => {
      if (clickCount === 0) {
        return 1;
      }
      if (clickCount === items - itemsPerView) {
        return 1;
      }
      return 2;
    })();
    setClickCount(clickCount);
    setScrollX(() => {
      const maxScroll = getMaxScroll(items, itemsPerView, arrowsOnScroll, arrowWidth);
      const newValue =
        ((wrapperRef.current?.clientWidth! - arrowWidth * arrowsOnScroll) / itemsPerView) *
        clickCount;
      return Math.min(Math.max(newValue, 0), maxScroll);
    });
    setArrowsOnScroll(arrowsOnScroll);
  }, [getMaxScroll, props.bundle.products.length, props.initialSlide]);

  useEffect(() => {
    const listener = debounce(() => {
      const items = props.bundle.products.length;
      // non vanno presi dallo stato perché non sono aggiornati dopo che è stato sparato l'evento del click sui pulsanti
      const itemsPerView = getItemsPerView();
      const arrowWidth = getArrowWidth();
      const arrowsOnScroll = (() => {
        if (clickCount === 0) {
          return 1;
        }
        if (clickCount === items - itemsPerView) {
          return 1;
        }
        return 2;
      })();
      // stessa logica che viene fatta al click
      setScrollX(() => {
        const maxScroll = getMaxScroll(items, itemsPerView, arrowsOnScroll, arrowWidth);
        const newValue =
          ((wrapperRef.current?.clientWidth! - arrowWidth * arrowsOnScroll) / itemsPerView) *
          clickCount;
        return Math.min(Math.max(newValue, 0), maxScroll);
      });
      setArrowsOnScroll(arrowsOnScroll);
    }, 500);

    window.screen.orientation.addEventListener("change", listener);
    window.addEventListener("resize", listener);

    return () => {
      window.screen.orientation.removeEventListener("change", listener);
      window.removeEventListener("resize", listener);
    };
  }, [arrowsOnScroll, clickCount, getMaxScroll, props.bundle.products.length]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const formatter = new Intl.NumberFormat("it", {
    style: "currency",
    currency: "EUR",
  });

  const rightArrowCheck =
    ((wrapperRef.current?.clientWidth! - arrowWidth * arrowsOnScroll) / itemsPerView) *
      (props.bundle.products.length - itemsPerView) -
    TAB_GAP; // massimo valore possibile per lo scroll con n tab
  const hideRightArrow = () => {
    if (props.bundle.products.length <= itemsPerView) {
      return true;
    }
    return scrollX === rightArrowCheck;
  };

  return (
    <div
      className="bundle-detail-header"
      ref={wrapperRef}
      style={
        {
          "--tab-gap": props.bundle.products.length <= itemsPerView ? "0px" : `${TAB_GAP}px`,
          "--arrow-width": `${arrowWidth}px`,
          "--items-per-view": itemsPerView,
        } as CSSProperties
      }
    >
      <button
        onClick={() => onClick("left")}
        className={classNames("bundle-detail-header__scroll-tabs", {
          "bundle-detail__scroll-tabs--hidden": scrollX === 0,
        })}
      >
        <Icon name="circle-chevron-left" className="bundle-detail-header__icon" />
      </button>
      <ul className={classNames("bundle-detail-header__products", "list")}>
        {props.bundle.products.map((p, i) => (
          <li
            className={classNames("bundle-detail-header__top-card", {
              "bundle-detail-header__top-card--selected": i === props.selected,
            })}
            style={{
              transform: `translateX(-${scrollX}px)`,
            }}
          >
            <button
              className={classNames("bundle-detail-header__top-card-button")}
              onClick={() => {
                props.setSelected(i);
              }}
            >
              <div>{p.typeElearningSystemName}</div>
              {p.formats[0].price !== undefined && (
                <div className={"bundle-detail-header__price-wrapper"}>
                  <span
                    className={classNames("bundle-detail-header__price", {
                      "bundle-detail-header__price--strike": p.formats[0].promotion?.price,
                    })}
                  >
                    {formatter.format(p.formats[0].price)}
                  </span>
                  {p.formats[0].promotion?.price && (
                    <span className="bundle-detail-header__price bundle-detail-header__price--highlight">
                      {formatter.format(p.formats[0].promotion?.price)}
                    </span>
                  )}
                </div>
              )}
            </button>
          </li>
        ))}
      </ul>
      <button
        onClick={() => onClick("right")}
        className={classNames(
          "bundle-detail-header__scroll-tabs bundle-detail-header__scroll-tabs--next",
          {
            "bundle-detail__scroll-tabs--hidden": hideRightArrow(),
          }
        )}
      >
        <Icon name="circle-chevron-right" className="bundle-detail-header__icon" />
      </button>
    </div>
  );
};

export default BundleDetailHeader;
