import Slider from "../components/slider";
import SearchWrapper from "../components/search-wrapper";
import { useNewBundles } from "../hooks/useQueryCustom";
import MenuPublic from "../components/menu-public";
import Seo from "../components/seo";
import CardContainerBundles from "../components/bundle/card-container-bundles";

const BUNDLES_TO_FETCH = 4;

const Home = () => {
  const bundles = useNewBundles("novita", BUNDLES_TO_FETCH);
  return (
    <>
      <Seo
        title="Pearson Place"
        sitename={false}
        description="Accedi ai miei prodotti per utilizzare o attivare i prodotti digitali associati ai tuoi libri di testo universitari"
      />
      <MenuPublic />
      <SearchWrapper>
        <Slider size={6} />
        <section className="section">
          <div className="container-md">
            <CardContainerBundles
              bundles={bundles}
              label="Novità"
              path="/bundle"
              bundlesPerPage={BUNDLES_TO_FETCH}
            />
          </div>
        </section>
      </SearchWrapper>
    </>
  );
};

export default Home;
