import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { useProduct, useAddCopyRequest } from "../hooks/useQueryCustom";
import { toast } from "react-toastify";

import Address from "../components/address";
import Select from "react-select";
import Icon from "../components/icon";
import FormInput from "../components/form-input";
import Seo from "../components/seo";
import { useState } from "react";
import LoadingError from "../components/loading-error";

const CopyRequest = () => {
  const { productId } = useParams<{ productId: string }>();
  const [isSuccessContact, setIsSuccessContact] = useState<Boolean>(false);
  const addCopyRequest = useAddCopyRequest();
  const state = useLocation().state;
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange", defaultValues: state?.formData });

  const { data, isError, isFetched } = useProduct("getProductByProductId", productId as string);

  const statusOptions = [
    {
      label: "Adottato",
      value: "Adottato",
    },
    {
      label: "In valutazione",
      value: "In valutazione",
    },
  ];

  const onSubmit = async (formData: any) => {
    try {
      const response = await addCopyRequest.mutateAsync({ formData });
      setIsSuccessContact(true);
    } catch (e) {
      toast.error("Si è verificato un errore");
    }
  };

  const saveForm = () => {};

  const product = data?.products.find(
    (i) => i.productId === Number(productId) || i.formats[0].isbn === productId
  );

  if (isError || (isFetched && !product)) {
    return <LoadingError className="error-block--page" />;
  }

  return (
    <div className="copy-request">
      <Seo title="Richiesta copia" />
      <div className="page__header">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <div className="page__header-text d-flex flex-column justify-content-center">
                <h1 className="page__header-title">Richiesta copia</h1>
                <h2 className="page__header-subtitle">{product?.productName}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content pb-5">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              {isSuccessContact ? (
                <div>Richiesta inviata con successo!</div>
              ) : (
                <>
                  <div className="row my-4 my-md-5">
                    <div className="col-md-8 mb-2 mb-md-0">
                      Ti preghiamo di aggiungere alcune informazioni necessarie per la richiesta:
                    </div>
                    <div className="col-md-4 text-left text-md-end">
                      <span className="page__label">* Campi obbligatori</span>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      type="hidden"
                      {...register("productId", { required: true })}
                      defaultValue={productId}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <FormInput
                          name="degreeCourse"
                          label="Corso di laurea*"
                          register={register}
                          required={true}
                          errors={errors}
                        />
                      </div>
                      <div className="col-md-6">
                        <FormInput
                          name="semester"
                          label="Semestre*"
                          register={register}
                          required={true}
                          errors={errors}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormInput
                          name="subject"
                          label="Insegnamento*"
                          required={true}
                          register={register}
                          errors={errors}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-7">
                            <FormInput
                              name="studentNumber"
                              pattern="\d*"
                              label="Numero prevedibile di studenti*"
                              required={true}
                              register={register}
                              errors={errors}
                            />
                          </div>
                          <div className="col-md-5">
                            <label className="form__label">Status adozione*</label>
                            <Controller
                              name="assessment"
                              control={control}
                              rules={{ required: true }}
                              defaultValue={statusOptions.find((el) => el.value === "Adottato")}
                              render={({ field }) => (
                                <Select
                                  aria-label="Status adozione"
                                  classNamePrefix="select"
                                  className="select-input"
                                  options={statusOptions}
                                  {...field}
                                />
                              )}
                            />
                            {errors.statusAdozione?.type === "required" && (
                              <p className="form__error" role="alert">
                                Questo campo è richiesto
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form__divider mt-2 mb-4"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Controller
                          name="address"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange } }) => (
                            <Address
                              onChange={onChange}
                              saveForm={saveForm}
                              getData={() => getValues()}
                            />
                          )}
                        />
                        {errors.address?.type === "required" && (
                          <p className="form__error" role="alert">
                            Questo campo è richiesto
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex justify-content-between">
                        <Link
                          className="button button--secondary my-3"
                          to={`/bundle/${data?.bundleId}?isbn=${product?.formats[0].isbn}`}
                        >
                          Annulla
                        </Link>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={classNames(
                            "button my-3",
                            { "button--tertiary": isValid },
                            { "button--disabled": !isValid || isSubmitting }
                          )}
                        >
                          <Icon name="paper-plane" width="26px" height="26px" />
                          Invia richiesta
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyRequest;
