import Skeleton from "react-loading-skeleton";
import { BundleItem } from "../../types/bundle";
import { Link } from "react-router-dom";

type CardBundleProps = {
  item: BundleItem;
};

const CardBundle = (props: CardBundleProps) => {
  const price = new Intl.NumberFormat("it-it", { style: "currency", currency: "EUR" }).format(
    props.item.price
  );
  return (
    <article className="card-bundle">
      <div className="card-bundle__img">
        <Link to={`/bundle/${props.item.bundleId}`}>
          <img src={props.item.coverImage} alt={props.item.title} />
        </Link>
        {props.item.newProduct && <span className="new-product">Novità</span>}
      </div>
      <div className="card-bundle__content card-bundle__spacer">
        <hgroup className="card-bundle__hgroup card-bundle__spacer">
          <p className="card-bundle__category">{props.item.categoryNameList}</p>
          <h3 className="card-bundle__title">
            <Link to={`/bundle/${props.item.bundleId}`}>{props.item.title}</Link>
          </h3>
          {props.item.subTitle && <p className="card-bundle__subtitle">{props.item.subTitle}</p>}
        </hgroup>
        {props.item.author.length > 0 && (
          <p className="card-bundle__authors">
            {props.item.author.map((p) => `${p.authorName} ${p.authorSurname}`).join(", ")}
          </p>
        )}
        <p className="card-bundle__description">
          {props.item.description && (
            <>
              <span>{props.item.description}</span> -{" "}
            </>
          )}
          <time dateTime={props.item.year}>{props.item.year}</time>
        </p>
        <p className="card-bundle__price">
          <Link to={`/bundle/${props.item.bundleId}`}>
            {props.item.formats.size > 1 ? (
              <>
                {`Disponibile in ${props.item.formats.size} formati a partire da`}
                <br />
                <strong className="card-bundle__price-text">{price}</strong>
              </>
            ) : (
              <>
                {`Disponibile in formato ${props.item.formats.formats}`}
                <br />
                <strong className="card-bundle__price-text">{price}</strong>
              </>
            )}
          </Link>
        </p>
      </div>
    </article>
  );
};

export const CardBundleSkeleton = () => {
  return (
    <div className="card-bundle">
      <Skeleton height={200} />
      <Skeleton count={5} />
    </div>
  );
};

export default CardBundle;
