import { gql } from "graphql-request";
import { callApiQl } from "./call-api";
import { FRAGMENT_BUNDLE, FRAGMENT_BUNDLES } from "./fragments";
import { BundleList, BundleWrapper } from "../types/bundle";
import { QueryFunction } from "@tanstack/react-query";
import { GROUP_ID } from "./search";

export async function getBundle(operationName: string, bundleId: string) {
  const variables = { bundleId };
  const data = await callApiQl<BundleWrapper>(
    gql`
      query ${operationName}($bundleId: Long!) {
        pearsonBundleById(bundleId: $bundleId) {
          ...BundleDetail
        }
      } ${FRAGMENT_BUNDLE}
    `,
    variables,
    false
  );
  return data;
}

export const getNewBundles: QueryFunction<BundleList> = async (ctx) => {
  const { queryKey, pageParam = 1 } = ctx;
  const [operationName, size] = queryKey;
  const filter = `newProduct eq true`;
  const data = await callApiQl(
    gql`
      query ${operationName} {
        pearsonBundles(groupId: ${GROUP_ID}, page: ${pageParam}, pageSize: ${size}, filter: \"${filter}\") {
          items {
            ...BundleDetail
          }
          page
          pageSize
          totalCount
        }
      }
      ${FRAGMENT_BUNDLES}
    `,
    null,
    false
  );
  return data;
};

export const getBundles: QueryFunction<BundleList> = async (ctx) => {
  const { queryKey, pageParam = 1 } = ctx;
  const [operationName, size] = queryKey;
  const data = await callApiQl(
    gql`
      query ${operationName} {
        pearsonBundles(groupId: ${GROUP_ID}, page: ${pageParam}, pageSize: ${size}) {
          items {
            ...BundleDetail
          }
          page
          pageSize
          totalCount
        }
      }
      ${FRAGMENT_BUNDLES}
    `,
    null,
    false
  );
  return data;
};
