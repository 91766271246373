import MenuPublic from "../components/menu-public";
import Seo from "../components/seo";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Products = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/bundle");
  }, [navigate]);

  return (
    <>
      <Seo title="Catalogo" />
      <MenuPublic />
    </>
  );
};

export default Products;
