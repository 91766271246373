import LoadingError from "../components/loading-error";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useProduct } from "../hooks/useQueryCustom";
import MenuPublic from "../components/menu-public";
import { Helmet } from "react-helmet-async";

const ProductDetail = () => {
  const { productId } = useParams<{ productId: string }>();
  const navigate = useNavigate();
  const { data, isError, isFetching, isFetched } = useProduct(
    "getProductByProductId",
    productId as string
  );

  useEffect(() => {
    if (isFetching || isError) {
      return;
    }

    const queryparams = productId?.length === 13 ? `?isbn=${productId}` : `?productId=${productId}`;

    data?.bundleId && navigate(`/bundle/${data.bundleId}${queryparams}`);
  }, [navigate, isError, data, isFetching, productId]);

  return isError || (isFetched && !data?.bundleId) ? (
    <LoadingError className="error-block--page" />
  ) : (
    <>
      {productId?.length === 13 && (
        <Helmet>
          <link rel="canonical" href={`${window.location.origin}/catalogo/${data?.bundleId}`} />
        </Helmet>
      )}
      <MenuPublic className="header-menu--border" />
    </>
  );
};

export default ProductDetail;
