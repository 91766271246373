import { useContext, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "./icon";
import UserContext from "../context/user";
import logo from "../assets/logo.png";
import logoMobile from "../assets/logo-mobile.png";
import classNames from "classnames";
import Greeting from "./greeting";
import UserMenu from "./usermenu";
import { isPrivateRoute } from "../utils/utility";
import { ROLE_ADMIN } from "../utils/constants";

const Header = () => {
  const [open, setOpen] = useState(false);
  const openMenu = () => setOpen(true);
  const closeMenu = () => setOpen(false);
  const { user, userImp, login, logout, stopImpersonification, isDocente, isStudente } =
    useContext(UserContext);
  const location = useLocation();

  return (
    <header className="header">
      {user?.role === ROLE_ADMIN && (
        <div className="row">
          <div className="col-12 ">
            {userImp ? (
              <div className="header__actas">
                Stai impersonificando:{" "}
                <strong>
                  {userImp.role} - {userImp?.userId}
                </strong>
                <button
                  className="button mx-5"
                  onClick={() => {
                    stopImpersonification();
                  }}
                >
                  Esci
                </button>
              </div>
            ) : (
              <div className="header__actas">Utente Admin</div>
            )}
          </div>
        </div>
      )}
      <div className="header__top">
        <div className="container-md">
          <div className="row">
            <div className="col-12 ">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="logo d-flex align-items-center">
                    <Link to="/" aria-label="Pearson">
                      <img src={logo} className="d-none d-md-block" alt="logo" />
                      <img src={logoMobile} className="d-md-none mr-1" alt="logo" />
                    </Link>
                    {isPrivateRoute(location.pathname) && user ? (
                      <Greeting user={user} />
                    ) : (
                      <>
                        <Icon className="logo__icon" name="caret" />
                        <span className="logo__text">Università</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="header__right d-flex align-items-center">
                  <nav id="menu-top" className={`menu-top${open ? " open" : ""}`}>
                    <ul className="d-md-flex align-items-center">
                      <li>
                        <NavLink className="menu-top__item menu-top__item" to="/bundle">
                          Catalogo
                        </NavLink>
                      </li>
                      {(isDocente() || isStudente()) && (
                        <li>
                          <NavLink className="menu-top__item menu-top__item--myhome" to="/my-home">
                            I miei prodotti
                          </NavLink>
                        </li>
                      )}
                      {isDocente() ? (
                        <li>
                          <NavLink
                            className="menu-top__item menu-top__item--private"
                            to="/contatta-consulente"
                          >
                            Contatta consulente
                          </NavLink>
                        </li>
                      ) : null}
                      {!user || isStudente() ? (
                        <li>
                          <NavLink
                            className="menu-top__item menu-top__item--private"
                            to="/attiva-prodotto"
                            onClick={(e: any) => {
                              if (user === null) {
                                e.preventDefault();
                                login(e.target.href);
                              }
                            }}
                          >
                            Attiva prodotto
                          </NavLink>
                        </li>
                      ) : null}

                      <button
                        className="menu-top__item d-inline"
                        onClick={() => {
                          user ? logout() : login(`${window.location.origin}/my-home`);
                        }}
                      >
                        {user ? "Logout" : "Login/Registrati"}
                      </button>

                      <li>
                        {/*  <NavLink
                          className={classNames("menu-top__item", {
                            "menu-top__item--private": isPrivateRoute(location.pathname),
                          })}
                          to={`/${user ? "my-" : ""}help`}
                        >
                          Help
                        </NavLink> */}
                        <a
                          className={classNames("menu-top__item", {
                            "menu-top__item--private": isPrivateRoute(location.pathname),
                          })}
                          href="https://support.pearson.com/getsupport/s/contactsupport"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Help
                        </a>
                      </li>
                    </ul>
                  </nav>

                  {/*  <div className="menu-top-icons">
                    <button className="menu-top-icons__button">
                      <Icon className="menu-top-icons__icon" name="cart" />
                    </button>
                  </div> */}
                  {user ? <UserMenu user={user} logout={logout} /> : null}
                  <div className="menu-top-icons">
                    {!open ? (
                      <button
                        aria-label="open menu"
                        className="menu-top-icons__button d-md-none"
                        onClick={openMenu}
                      >
                        <Icon className="menu-top-icons__icon" name="bars" />
                      </button>
                    ) : null}
                    {open ? (
                      <button
                        aria-label="close menu"
                        className="menu-top-icons__button"
                        onClick={closeMenu}
                      >
                        <Icon className="menu-top-icons__icon" name="close" />
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames("header__bottom", {
          "header__bottom--private": isPrivateRoute(location.pathname),
        })}
      ></div>
    </header>
  );
};

export default Header;
