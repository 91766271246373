import classNames from "classnames";
import { Spinner } from "../spinner";
import { useContext, useState } from "react";
import { useSyncBigCommercePrice } from "../../hooks/useQueryCustom";
import UserContext from "../../context/user";
import Icon from "../icon";
import { callBigCommerce } from "../../utils/big-commerce";
import CustomModal from "../modal";

type BuyProductProps = {
  isbn: string;
};

const BuyProduct = (props: BuyProductProps) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const { user, login, checkIESSession } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);
  const syncBigCommercePrice = useSyncBigCommercePrice();
  const [openPopupError, setOpenPopupError] = useState<boolean>(false);

  const goToBigCommerce = async (isbn: string) => {
    setSubmitting(true);

    if (user) {
      try {
        await syncBigCommercePrice.mutateAsync({ isbn });
        if (checkIESSession()) {
          window.piSession.getToken((status: string, token: string) => {
            callBigCommerce(token, isbn);
          });
        }
      } catch (error) {
        setSubmitting(false);
        setOpenPopupError(true);
      }
    } else {
      setSubmitting(false);
      setOpenPopup(true);
    }
  };

  return (
    <>
      <button
        onClick={() => goToBigCommerce(props.isbn)}
        title="Acquista online"
        disabled={submitting}
        className="button button--tertiary"
      >
        {submitting && <Spinner className="button__loader" />}
        <Icon
          className={classNames("icon", submitting && "invisible")}
          name="cart"
          width="29px"
          height="22px"
        />
        <span className={classNames(submitting && "invisible")}>Acquista</span>
      </button>
      <CustomModal
        show={openPopup}
        headerText="Attenzione"
        type="confirm"
        confirmButtonText="Login / Registrati"
        scrollable={false}
        onConfirm={() => login()}
        onHide={() => setOpenPopup(false)}
      >
        <div>Per acquistare il prodotto devi essere registrato o effettuare il login</div>
      </CustomModal>

      <CustomModal
        show={openPopupError}
        headerText="Attenzione"
        type="alert"
        scrollable={false}
        onHide={() => setOpenPopupError(false)}
      >
        <div>Spiacenti, non è possibile completare l'acquisto. Riprovare più tardi.</div>
      </CustomModal>
    </>
  );
};

export default BuyProduct;
