import Skeleton from "react-loading-skeleton";
import Backlink from "../components/backlink";
import LoadingError from "../components/loading-error";
import MenuPublic from "../components/menu-public";
import { useBundle } from "../hooks/useQueryCustom";
import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import UserContext from "../context/user";
import { ROLE_TEACHER } from "../utils/constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CustomModal from "../components/modal";
import BundleDetailHeader from "../components/bundle/bundle-detail-header";
import BundleDetailBody from "../components/bundle/bundle-detail-body";
import Seo from "../components/seo";

const BundleDetail = () => {
  const { user, login } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { bundleId = "" } = useParams<{ bundleId: string }>();
  const { data, isError, isFetching, isFetched } = useBundle("bundle", bundleId);

  const [productIndex, setProductIndex] = useState(0);
  const [initialSlide, setInitialSlide] = useState({
    slide: 0,
    done: false,
  });
  const [openProfessorPopup, setOpenProfessorPopup] = useState(false);

  const date = dayjs(data?.products[productIndex].publishDate).format("YYYY");
  const navigate = useNavigate();
  const richiediCopia = (id: number) => {
    if (user && user.role === ROLE_TEACHER) {
      navigate(`/richiesta_copia/${id}`);
    } else {
      setOpenProfessorPopup(true);
    }
  };

  useEffect(() => {
    // controllo per evitare di risettare la slide iniziale tutte le volte che cambia la query string
    if (!isFetched || initialSlide.done) {
      return;
    }

    const productId = searchParams.get("productId");
    const isbn = searchParams.get("isbn");

    if (!productId && !isbn) {
      return;
    }

    const i = productId
      ? data?.products.findIndex((p) => p.productId === Number(productId)) ?? -1
      : data?.products.findIndex((p) => p.formats[0].isbn === isbn) ?? -1;

    if (i === -1) {
      return;
    }
    setProductIndex(i);
    setInitialSlide({
      done: true,
      slide: i,
    });
    setSearchParams({ isbn: data?.products[i].formats[0].isbn.toString() ?? "" });
  }, [data?.products, initialSlide.done, isFetched, searchParams, setSearchParams]);

  const price =
    data?.products[productIndex].formats[0].price !== undefined
      ? new Intl.NumberFormat("it", { style: "currency", currency: "EUR" }).format(
          data?.products[productIndex].formats[0].price as number
        )
      : undefined;
  const promotionPrice = data?.products[productIndex].formats[0]?.promotion?.price;
  const promotionPriceParsed = promotionPrice
    ? new Intl.NumberFormat("it", { style: "currency", currency: "EUR" }).format(promotionPrice)
    : undefined;
  return (
    <div className="bundle-detail">
      <Seo title={data?.title} />
      <MenuPublic className="header-menu--border" />

      {isError && <LoadingError className="error-block--page" />}
      {!isError && (
        <main className="container bundle-detail__main">
          <Backlink path="bundle" anchor="Vedi tutti i prodotti" />
          <div>
            <div>
              {isFetching ? (
                <Skeleton height={"40px"} className="bundle-detail__title" />
              ) : (
                <h1 className="bundle-detail__title bundle-detail__heading">{data?.title}</h1>
              )}
            </div>
            <div>
              {data && (
                <BundleDetailHeader
                  bundle={data}
                  selected={productIndex}
                  initialSlide={initialSlide.slide}
                  setSelected={(i) => {
                    setProductIndex(i);
                    setSearchParams({ isbn: data?.products[i].formats[0].isbn.toString() ?? "" });
                  }}
                />
              )}
              <BundleDetailBody
                data={data}
                date={date}
                isFetching={isFetching}
                productIndex={productIndex}
                richiediCopia={richiediCopia}
                price={price}
                promotionPrice={promotionPriceParsed}
              />
            </div>
          </div>
          <CustomModal
            show={openProfessorPopup}
            headerText="Attenzione"
            type="confirm"
            confirmButtonText="Login / Registrati"
            scrollable={false}
            onConfirm={() => login()}
            onHide={() => setOpenProfessorPopup(false)}
          >
            <div>
              Per richiedere una copia saggio è necessario effettuare il login ed essere docenti
              universitari.
            </div>
          </CustomModal>
        </main>
      )}
    </div>
  );
};

export default BundleDetail;
