import Skeleton from "react-loading-skeleton";
import { Bundle } from "../../types/bundle";
import { ROLE_TEACHER } from "../../utils/constants";
import Icon from "../icon";
import { useContext, useState } from "react";
import UserContext from "../../context/user";
import classNames from "classnames";
import Description from "../description";
import { useResourcesVisibility } from "../../utils/utility";
import BuyProduct from "./buy-product";

type Tab = "descrizione" | "indice" | "risorse";
type BundleFooterProps = {
  data: Product;
};

const BundleFooter = (props: BundleFooterProps) => {
  const [selected, setSelected] = useState<Tab>("descrizione");
  const resVisibility = useResourcesVisibility(props.data.resourcesAccessRole);
  return (
    <footer className="bundle-detail__item-footer bundle-detail-footer">
      <ul className="bundle-detail-footer__tabs list">
        {(props.data.author.length > 0 || props.data.productDescription) && (
          <li>
            <button
              className={classNames("bundle-detail-footer__tab", {
                "bundle-detail-footer__tab--selected": selected === "descrizione",
              })}
              onClick={() => setSelected("descrizione")}
            >
              Descrizione
            </button>
          </li>
        )}
        {props.data.index && (
          <li>
            <button
              className={classNames("bundle-detail-footer__tab", {
                "bundle-detail-footer__tab--selected": selected === "indice",
              })}
              onClick={() => setSelected("indice")}
            >
              Indice
            </button>
          </li>
        )}
        {resVisibility && props.data.resources && (
          <li>
            <button
              className={classNames("bundle-detail-footer__tab", {
                "bundle-detail-footer__tab--selected": selected === "risorse",
              })}
              onClick={() => setSelected("risorse")}
            >
              Risorse
            </button>
          </li>
        )}
      </ul>
      <div className="bundle-detail-footer__content spacer">
        {selected === "descrizione" && (
          <>
            <section className="bundle-detail-footer__authors bundle-detail__spacer">
              {props.data.productDescription && (
                <Description description={props.data.productDescription} />
              )}
            </section>
          </>
        )}
        {selected === "indice" && props.data.index && (
          <>
            <Description description={props.data.index} />
          </>
        )}
        {selected === "risorse" && props.data.resources && (
          <Description description={props.data.resources} />
        )}
      </div>
    </footer>
  );
};

type BundleDetailBodyProps = {
  data: Bundle | undefined;
  isFetching: boolean;
  productIndex: number;
  date: string;
  price?: string;
  promotionPrice?: string;
  richiediCopia: (id: number) => void;
};

const BundleDetailBody = ({
  data,
  isFetching,
  productIndex,
  date,
  price,
  promotionPrice,
  richiediCopia,
}: BundleDetailBodyProps) => {
  const { user } = useContext(UserContext);
  return (
    <article className="bundle-detail__item">
      <div className="bundle-detail__item-detail bundle-detail__spacer">
        <div className="bundle-detail__image">
          {isFetching ? (
            <Skeleton height={"240px"} />
          ) : (
            <>
              <img src={data?.products[productIndex].coverImage} alt="placeholder" />
              {data?.products[productIndex].newProduct && <div className="new-product">Novità</div>}
              {!data?.products[productIndex].newProduct &&
                (data?.products[productIndex].tags?.length ?? 0) > 0 && (
                  <div className="new-product">{data?.products[productIndex].tags[0].tagName}</div>
                )}
            </>
          )}
        </div>
        <div className="bundle-detail__product-data bundle-detail__spacer">
          {isFetching ? (
            <Skeleton width={"300px"} count={5} />
          ) : (
            <>
              <hgroup className="bundle-detail__hgroup">
                <p>{data?.products[productIndex].subjectCategoryName}</p>
                <h2 className="bundle-detail__heading">
                  {data?.products[productIndex].productName}
                </h2>
              </hgroup>
              <div>
                <p>
                  {data?.products[productIndex].descriptionEdition && (
                    <>
                      <span>{data?.products[productIndex].descriptionEdition}</span> -{" "}
                    </>
                  )}
                  <time dateTime={date}>{date}</time>
                </p>
                <p className="bundle-detail__authors">
                  {(data?.products[productIndex].author ?? [])
                    .map((a) => `${a.authorName} ${a.authorSurname}`)
                    .join(" - ")}
                </p>
              </div>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.products?.[productIndex]?.productAbstract ?? "",
                  }}
                ></div>
                <p className="bundle-detail__isbn">
                  {`ISBN ${data?.products?.[productIndex]?.formats?.[0]?.isbn ?? ""}`}
                </p>
              </div>
              {(!user || user?.role === ROLE_TEACHER) && (
                <div className="bundle-detail__request-copy">
                  <button
                    onClick={() => richiediCopia(data?.products[productIndex].productId as number)}
                    className=" button button--light"
                  >
                    <Icon name="essay" width="20px" height="20px" />
                    <span>Richiedi copia saggio</span>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {price && (
          <div className="bundle-detail__price-wrapper">
            <div className="bundle-detail__price-container">
              {promotionPrice && <div className="bundle-detail__discount">In offerta</div>}
              <span
                className={classNames("bundle-detail__price", {
                  "bundle-detail__price--strike": promotionPrice,
                })}
              >
                {price}
              </span>
              {promotionPrice && <span className="bundle-detail__price">{promotionPrice}</span>}
            </div>
            {data?.products[productIndex].formats[0].isbn && data?.products[productIndex].formats[0].isEcommerce && (
              <BuyProduct isbn={data?.products[productIndex].formats[0].isbn} />
            )}
          </div>
        )}
      </div>
      {data && <BundleFooter data={data?.products[productIndex] as Product} />}
    </article>
  );
};

export default BundleDetailBody;
