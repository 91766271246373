import { gql } from "graphql-request";

export const FRAGMENT_PRODUCT = gql`
  fragment ProductDetail on PearsonProduct {
    accessType
    coverImage
    descriptionEdition
    expirationDate
    draft
    groupId
    index
    learningType
    link
    productAbstract
    productCategory
    productCategoryName
    productDescription
    productId
    productName
    productSubtitle
    publishDate
    resources
    subjectCategory
    subjectCategoryName
    moodleId
    defaultProduct
    typeElearningSystem
    activationLink
    accessLink
    resourcesAccessRole
    author {
      authorBio
      authorId
      authorName
      authorSurname
    }
    formats {
      formatId
      groupId
      formatType
      isEcommerce
      urlEcommerce
      pageNumber
      price
      title
      isbn
      promotion {
        promotionId
        label
        price
      }
    }
    tags {
      tagId
      tagName
    }
  }
`;

export const FRAGMENT_PRODUCT_CARD_AND_SLIDE = gql`
  fragment ProductDetail on PearsonProduct {
    bundleId
    coverImage
    descriptionEdition
    learningType
    productAbstract
    productId
    productName
    productSubtitle
    productCategory
    newProduct
    important
    productCategoryName
    subjectCategory
    subjectCategoryName
    publishDate
    moodleId
    defaultProduct
    typeElearningSystem
    activationLink
    accessLink
    resourcesAccessRole
    author {
      authorId
      authorName
      authorSurname
    }
    formats {
      price
      promotion {
        promotionId
        label
        price
      }
    }
    tags {
      tagId
      tagName
    }
  }
`;

export const FRAGMENT_COURSE = gql`
  fragment CourseDetail on PearsonCourse {
    code
    courseId
    createDate
    groupId
    isIndipendent
    name
    url
    productId
  }
`;

export const FRAGMENT_BUNDLE = gql`
  fragment BundleDetail on PearsonBundleProducts {
    bundleId
    groupId
    title
    products {
      productId
      groupId
      moodleId
      productName
      productSubtitle
      productAbstract
      productDescription
      coverImage
      publishDate
      expirationDate
      learningType
      accessType
      link
      index
      resources
      resourcesAccessRole
      productCategory
      productCategoryName
      subjectCategory
      subjectCategoryName
      descriptionEdition
      typeElearningSystem
      typeElearningSystemName
      activationLink
      accessLink
      draft
      important
      newProduct
      defaultProduct
      author {
        authorBio
        authorId
        authorName
        authorSurname
      }
      formats {
        formatId
        groupId
        formatType
        isEcommerce
        urlEcommerce
        pageNumber
        price
        title
        isbn
        promotion {
          promotionId
          label
          price
        }
      }
      tags {
        tagId
        tagName
      }
    }
  }
`;

export const FRAGMENT_BUNDLES = gql`
  fragment BundleDetail on PearsonBundle {
    bundleId
    groupId
    title
    subTitle
    description
    categoryNameList
    coverImage
    price
    formats {
      size
      formats
    }
    newProduct
    year
    author {
      authorBio
      authorId
      authorName
      authorSurname
    }
  }
`;
