import CardContainerBundles from "../components/bundle/card-container-bundles";
import MenuPublic from "../components/menu-public";
import SearchWrapper from "../components/search-wrapper";
import Seo from "../components/seo";
import Slider from "../components/slider";
import { useBundles } from "../hooks/useQueryCustom";

const BUNDLES_PER_PAGE = 6;

const Bundle = () => {
  const bundles = useBundles("getBundles", BUNDLES_PER_PAGE);
  return (
    <div className="bundle">
      <Seo title="Bundle" />
      <MenuPublic className="header-menu--border" />
      <SearchWrapper>
        <Slider type="prodotto" />
        <main className="section">
          <div className="container-md">
            <CardContainerBundles
              bundles={bundles}
              label="Catalogo"
              showMore={true}
              bundlesPerPage={BUNDLES_PER_PAGE}
            />
          </div>
        </main>
      </SearchWrapper>
    </div>
  );
};

export default Bundle;
