import { useContext, useMemo } from "react";
import { settori } from "../utils/constants";
import UserContext from "../context/user";

export const stripHtml = (html: string) => {
  const dom = new DOMParser().parseFromString(html, "text/html");
  return dom.body.textContent || dom.body.innerText || "";
};

export const isPrivateRoute = (pathname: string) => {
  const privateRoutes = [
    `/my-home`,
    `/my-help`,
    `/my-catalogo`,
    `/my-webinar`,
    `/my-formazione`,
    `/contatta-consulente`,
    `/attiva-prodotto`,
  ];
  return privateRoutes.includes(pathname);
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getDetailPage = (productCategoryName: string) => {
  const detailPage = {
    prodotto: "catalogo",
    webinar: "webinar",
    formazione: "formazione",
  }[productCategoryName.toLowerCase()];

  return detailPage;
};

export const getSettori = () => {
  return settori.map((item) => {
    const value = item.value.concat(" ", item.label).substring(0, 74);
    return {
      value: value,
      label: `${item.value} ${item.label}`,
    };
  });
};

export const getFormatTypeDesc = (formatType: number) => {
  switch (formatType) {
    case 1:
      return "cartaceo + digitale";
    case 2:
      return "digitale";
    case 3:
      return "cartaceo";
    default:
      return "";
  }
};

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
};

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition ? wrapper(children) : children;

export const useResourcesVisibility = (resourcesAccessRole: number) => {
  const { isDocente, isStudente, user } = useContext(UserContext);

  return useMemo(() => {
    if (resourcesAccessRole === 0 && isDocente()) {
      return true;
    } else if (resourcesAccessRole === 1 && isStudente()) {
      return true;
    } else if (resourcesAccessRole === 2 && user) {
      return true;
    } else if (resourcesAccessRole === 3) {
      return true;
    } else {
      return false;
    }
  }, [isDocente, isStudente, resourcesAccessRole, user]);
};
