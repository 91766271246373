import { BundleList } from "../types/bundle";
import { callApiQl } from "../utils/call-api";
import { gql } from "graphql-request";

export const VOCABULARY_TYPE_ID = import.meta.env.VITE_VOCABULARY_TYPE_ID;
export const VOCABULARY_SUBJECT_ID = import.meta.env.VITE_VOCABULARY_SUBJECT_ID;
export const GROUP_ID = import.meta.env.VITE_GROUP_ID;

export const getTypeAndSubjects = async (operationName: string, forceMock?: boolean) => {
  const data = await callApiQl(
    gql`
      query ${operationName} {
        subject: taxonomyVocabularyTaxonomyCategories(taxonomyVocabularyId: ${VOCABULARY_SUBJECT_ID}, sort: "name", pageSize: 50 ){
          items{
            name,
            id
          }
        }
        ptype: taxonomyVocabularyTaxonomyCategories(taxonomyVocabularyId: ${VOCABULARY_TYPE_ID}, sort: "name", pageSize: 50 ){
          items{
            name,
            id
          }
        }
      }
      `,
    null,
    forceMock || false
  );
  return data;
};

export const search = async (key: any) => {
  const { queryKey, pageParam = 1 } = key;

  const [operationName, searchParams]: [string, SearchParam] = queryKey;
  const { query = "", subject, order = "date", pageSize } = searchParams;

  const filter = subject ? `contains(subjectCategoryName,'${subject}')` : "";

  const orderBy = {
    date: 'sort: "publishYear:desc"',
    titleAsc: 'sort: "bundleName:asc"',
    titleDesc: 'sort: "bundleName:desc"',
  }[order];

  const data = await callApiQl<BundleList>(
    gql`
      query ${operationName}  {
        pearsonBundles(search: "${query}", filter: "${filter}", groupId: ${GROUP_ID}, page: ${pageParam}, pageSize: ${pageSize}, ${orderBy}) {
          items {
            bundleId
            groupId
            title
            subTitle
            description
            categoryNameList
            coverImage
            price
            formats {
              size
              formats
            }
            newProduct
            year
            author {
              authorBio
              authorId
              authorName
              authorSurname
            }
          }
          page
          pageSize
          totalCount
        }
      }
      `,
    null,
    false
  );
  return data;
};
