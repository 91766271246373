import dayjs from "dayjs";
import Backlink from "../components/backlink";
import Tabs from "../components/tabs";
import Skeleton from "react-loading-skeleton";
import LoadingError from "../components/loading-error";
import { stripHtml } from "../utils/utility";
import { useParams } from "react-router-dom";
import { useProduct } from "../hooks/useQueryCustom";
import MenuPublic from "../components/menu-public";

const WebinarDetail = () => {
  const { webinarId } = useParams<{ webinarId: string }>();
  const { data: bundle, isError } = useProduct("getWebinarByWebinarId", webinarId as string);
  const data = bundle?.products.find((p) => p.productId === Number(webinarId));

  return isError ? (
    <LoadingError className="error-block--page" />
  ) : (
    <>
      <MenuPublic />
      <div className="page-detail">
        <div className="container-md">
          <div className="row">
            <div className="col-md-12 ">
              <Backlink path="webinar" anchor="Vedi tutti i webinar" />
              <div className="d-flex flex-column flex-md-row align-items-start">
                <div className="page-detail__cover-image d-flex mb-3">
                  {data?.coverImage ? (
                    <img src={data.coverImage} alt={data.productName} />
                  ) : (
                    <Skeleton width="232px" height="289px" />
                  )}
                </div>
                <div className="page-detail__content flex-grow-1">
                  {data ? (
                    <>
                      <h1 className="page-detail__title">{data?.productName}</h1>
                      {data?.productSubtitle && (
                        <div className="page-detail__subtitle">{data.productSubtitle}</div>
                      )}
                      <div className="page-detail__date">
                        DATA:{" "}
                        {data?.publishDate ? dayjs(data?.publishDate).format("DD/MM/YYYY") : null}{" "}
                      </div>
                      <div className="page-detail__author">
                        {data?.author?.[0]?.authorName} {data?.author?.[0]?.authorSurname}
                      </div>
                      {data?.productAbstract && (
                        <div className="page-detail__abstract">
                          {stripHtml(data?.productAbstract)}
                        </div>
                      )}
                      {data?.subjectCategoryName && (
                        <div className="page-detail__subject">
                          <span className="page-detail__label">Materia</span>
                          <span>{data?.subjectCategoryName}</span>
                        </div>
                      )}
                      <Tabs
                        sessions={data?.formats}
                        description={data?.productDescription}
                        resources={data?.resources}
                      />
                    </>
                  ) : (
                    <Skeleton count={5.5} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebinarDetail;
