import Backlink from "../components/backlink";
import LoadingError from "../components/loading-error";
import { useState, Fragment } from "react";
import Tabs from "../components/tabs";
import CustomModal from "../components/modal";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useProduct } from "../hooks/useQueryCustom";
import MenuPublic from "../components/menu-public";

const LearningDetail = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openAuthorPopup, setOpenAuthorPopup] = useState<boolean>(false);
  const [author, setAuthor] = useState<Author | null>(null);
  const { learningId } = useParams<{ learningId: string }>();

  const { data: bundle, isError } = useProduct("getLearningByLearningId", learningId as string);
  const data = bundle?.products.find((p) => p.productId === Number(learningId));

  const onHide = () => {
    setOpenPopup(false);
  };

  const onConfirm = () => {
    setOpenPopup(false);
  };

  return isError ? (
    <LoadingError className="error-block--page" />
  ) : (
    <>
      <MenuPublic />
      <div className="page-detail">
        <div className="container-md">
          <div className="row">
            <div className="col-md-12 ">
              <Backlink path="formazione" anchor="Vedi tutta la formazione" />
              <div className="d-flex flex-column flex-md-row align-items-start">
                <div className="page-detail__cover-image d-flex mb-3">
                  {data?.coverImage ? (
                    <img src={data.coverImage} alt={data.productName} />
                  ) : (
                    <Skeleton width="232px" height="289px" />
                  )}
                </div>
                <div className="page-detail__content flex-grow-1">
                  {data ? (
                    <>
                      <h1 className="page-detail__title">{data.productName}</h1>

                      <div className="page-detail__author">
                        {data.author.map((author: Author, index: number) => (
                          <Fragment key={index}>
                            {author.authorBio ? (
                              <button
                                onClick={() => {
                                  setAuthor(author);
                                  setOpenAuthorPopup(true);
                                }}
                              >
                                {author.authorName} {author.authorSurname}
                              </button>
                            ) : (
                              <span>
                                {author.authorName} {author.authorSurname}
                              </span>
                            )}
                            {index < data.author.length - 1 ? <span> - </span> : ""}
                          </Fragment>
                        ))}
                      </div>
                      {data.productAbstract && (
                        <div
                          className="page-detail__abstract"
                          dangerouslySetInnerHTML={{
                            __html: data.productAbstract,
                          }}
                        ></div>
                      )}
                      {data.subjectCategoryName && (
                        <div className="page-detail__subject">
                          <span className="page-detail__label">Materia</span>
                          <span>{data.subjectCategoryName}</span>
                        </div>
                      )}
                      <Tabs
                        sessions={data.formats}
                        description={data.productDescription}
                        resources={data.resources}
                      />
                    </>
                  ) : (
                    <Skeleton count={5.5} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          show={openAuthorPopup}
          headerText={`${author?.authorName} ${author?.authorSurname}`}
          type="alert"
          scrollable={false}
          onHide={() => setOpenAuthorPopup(false)}
        >
          <div dangerouslySetInnerHTML={{ __html: author ? author?.authorBio : "" }} />
        </CustomModal>
        <CustomModal
          show={openPopup}
          headerText="Attenzione"
          type="alert"
          onHide={onHide}
          onConfirm={onConfirm}
        >
          <div>Sono nel popup</div>
        </CustomModal>
      </div>
    </>
  );
};

export default LearningDetail;
